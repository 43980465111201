import * as angular from 'angular';
// Injection of each class
import Grid from "echarts/types/src/coord/cartesian/Grid";
import {ResultsBoxService} from "angular-blocworx/components/fields/results-box/results-box.service";
import {scanStationCtrlObj} from '@/legacy/controllers/ScanStationCtrlObj';
import {CalendarResourcePlanningService} from "angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning.service";

"use strict";

angular.module('GridModule')
    .controller('gridScanStationController', GridScanStationController);


GridScanStationController.$inject = ['GridFactory', 'UnknownExceptionFactory', 'ArgumentUndefinedExceptionFactory',
    'Configuration', '$scope', '$rootScope', 'scanStation', 'ScopeAndLocal', 'ScanStationFactory', 'SCS', '$timeout', 'GridService', 'ResultsBoxService', 'AngularScanStationService','CalendarResourcePlanningService'];

// Controller Constructor
function GridScanStationController(GridFactory, UnknownExceptionFactory, ArgumentUndefinedExceptionFactory,
                                   Configuration, $scope, $rootScope, scanStation, ScopeAndLocal, ScanStationFactory, SCS, $timeout, GridService, ResultsBoxService, AngularScanStationService, CalendarResourcePlanningService) {

    // Creating the view model reference
    let vm = this;

    vm.$onInit = function () {
        try {
            vm.validate = false;
            const version = vm.version = Configuration.getVersion();
            const random = Configuration.getRandomValue();
            vm.scanStationService = SCS;

            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            $scope.fc = vm.fc;

            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            $scope.scanStation = vm.scanStation;

            ScopeAndLocal.startVariable(vm, $scope, 'main');
            $scope.main = vm.main;

            ScopeAndLocal.startVariable(vm, $scope, 'data');
            $scope.data = vm.data;

            ScopeAndLocal.startVariable(vm, $scope, 'field');
            $scope.field = vm.field;

            vm.lines = [];
            vm.refreshGrid = false;

            vm.startScanStationFunctions();


        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will load once that we build the scan station edit.
     */
    vm.broadcastGridData = () => {
        $scope.$on('reloadSpecificGrids', async function (event, gridIdsToUpdate) {
            if (vm.refreshGrid == false) {
                vm.refreshGrid = true;
                await vm.reloadSpecificGrids(gridIdsToUpdate);
                vm.refreshGrid = false;
            }
        });
    }

    /**
     * This will load the field for a grid element.
     *
     * @param field_id
     * @param gridfield
     */


    /**
     * Method that checks if a grid should be
     * enabled or disabled
     * @returns {boolean} true: enables | false : disabled
     */
    vm.isGridEnabled = function () {
        try {
            return GridFactory.isGridEnabled()
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Method that will return the status in a text format
     * @returns {string|string}
     */
    vm.gridStatusText = function () {
        try {
            return GridFactory.getGridStatusText()
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Method that will return the status in a text format
     * @returns {string|string}
     */
    vm.getGridButtonAction = function () {
        try {
            return GridFactory.getGridButtonAction()
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Method that will return the status in a text format
     * @returns {*}
     */
    vm.semaphoreClick = function () {
        try {
            return GridFactory.semaphoreClick()
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Removing the quantity from the child count
     * @param child
     * @returns {*}
     */
    vm.removeQuantity = function (child) {
        try {
            return GridFactory.removeQuantity(child)
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Method that will do the validation of scan station form validate
     */
    vm.scanStationFormValidate = function (field, line) {
        try {

            if ((vm.gridPopUpOpen == true || vm.embedState == true) && (line.usable != true && vm.previousStateForField != null && vm.previousStateForField[field.field_slug] != null)) {
                return vm.previousStateForField[field.field_slug];
            }

            let validate = GridService.scanStationFormValidate(vm.scanStation, field);

            // if we do not want to make changes based on data changes (e.g. due to pop ups etc)
            // then we need to store the previous state of the field incase we need it later
            if (vm.previousStateForField == null) {
                vm.previousStateForField = {};
            }

            vm.previousStateForField[field.field_slug] = validate;

            if (validate != null) {
                vm.validate = validate;
            }

            if (vm.scanStation.scanStationObj.visuallyHiddenFieldsViaConditionalVisibility == null) {
                vm.scanStation.scanStationObj.visuallyHiddenFieldsViaConditionalVisibility = [];
            }

            // when we only visually hide the field, validate is always true because we do not want to
            // completely remove the field, we do however want to add or remove it from the visuallyHiddenFieldsViaConditionalVisibility array

            if (field.conditionalVisibilityVisualOnly == 'yes' && validate == false) {
                if (!vm.scanStation.scanStationObj.visuallyHiddenFieldsViaConditionalVisibility.includes(field.field_slug)) {
                    vm.scanStation.scanStationObj.visuallyHiddenFieldsViaConditionalVisibility.push(field.field_slug);
                }
                vm.validate = true;
            } else if (field.conditionalVisibilityVisualOnly == 'yes' && validate == true) {
                let index = vm.scanStation.scanStationObj.visuallyHiddenFieldsViaConditionalVisibility.indexOf(field.field_slug);
                if (index > -1) {
                    vm.scanStation.scanStationObj.visuallyHiddenFieldsViaConditionalVisibility.splice(index, 1);
                }

                vm.validate = true
            }

            return vm.validate;

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Function that will check if the current user is valid to show
     * elements.
     * @returns {*}
     */
    vm.isValidUser = function (main) {
        try {
            return GridFactory.isValidUser(main);
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Function responsible for the click action of the semaphore.
     * @returns {*}
     */
    vm.semaphoreClick = function () {
        try {
            return GridFactory.semaphoreClick();
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Function responsible for the click action of the semaphore.
     * @returns {*}
     */
    vm.gridSemaphoreURL = function () {
        try {
            return GridFactory.gridSemaphoreURL();
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Method that will connect the Scan Station Fields data but only for specific grids
     * @param scanStation
     */

    vm.reloadSpecificGrids = async function (gridIdsToUpdate) {
        try {
            // loading the scanStationFields for this screen
            if (typeof vm.scanStation.scanStationObj != 'undefined') {

                let response = await GridFactory.getSpecificGridLines(vm.scanStation.id, gridIdsToUpdate);
                angular.forEach(vm.lines, function (line, lineKey) {
                    if (typeof line['children'] != 'undefined') {
                        angular.forEach(line['children'], function (child, childId) {
                            angular.forEach(response.data.returnedGridsToUpdate, function (childToUpdate, childIdToUpdate) {
                                if (childIdToUpdate == childId) {
                                    vm.lines[lineKey]['children'][childId] = childToUpdate;
                                }
                            });
                        });
                    }
                });
                // TODO: Rafael Check this later
                let updatedValues = vm.lines;
                vm.lines = [];
                $scope.$apply();
                vm.lines = updatedValues;
                $scope.$apply();
                // TODO: Rafael Check this later

            }

        } catch (exception) {

            switch (exception.constructor.name) {
                case ('ArgumentUndefinedException'):
                    console.log('ArgumentUndefinedException');
                    console.log(exception.message);
                    break;

                case ('MissingScanStationFieldsException'):
                    console.log('MissingScanStationFieldsException');
                    console.log(exception.message);
                    break;

                default:
                    console.log(exception.message);
            }
        }
    }

    /**
     * Method that will connect the Scan Station Fields data.
     * Temp lines reason: https://stackoverflow.com/questions/29849293/ng-repeat-not-updating-on-update-of-array
     * @param scanStation
     */
    vm.loadData = async function () {

        try {

            // loading the scanStationFields for this screen
            if (vm.scanStation != undefined && vm.scanStation.id != undefined && vm.scanStation.scanStationObj != undefined) {
                let response = await GridFactory.getGridLines(vm.scanStation.id);
                vm.lines = [];
                vm.lines = response.data.lines;

            }

        } catch (exception) {

            switch (exception.constructor.name) {
                case ('ArgumentUndefinedException'):
                    console.log('ArgumentUndefinedException');
                    console.log(exception.message);
                    break;

                case ('MissingScanStationFieldsException'):
                    console.log('MissingScanStationFieldsException');
                    console.log(exception.message);
                    break;

                default:
                    console.log(exception.message);
            }
        }
    }


    /**
     * Method to add a new grid item, can be a full new row, or a child
     * @param scanStation
     * @param gridWidth
     * @param parentId
     */

    vm.addGridItem = async function (scanStation, gridWidth, parentId = null) {
        try {

            await GridFactory.addGridItem(scanStation.id, gridWidth, parentId);
            await vm.loadData();

        } catch (exception) {
            console.log(exception);
            if (exception.data.message) {
                alert(exception.data.message);
            }
        }
    }

    /**
     * Method to delete an element
     * @param gridId
     */

    vm.deleteGridItem = async function (gridId, parentId) {
        try {
            await GridFactory.deleteGridItem(gridId);
            await vm.loadData();
        } catch (exception) {
            console.log(exception);
            if (exception.data.message) {
                alert(exception.data.message);
            }
        }
    }

    /**
     * Method to move a field to another element
     * @param elementIdToUpdateTo
     * @param fieldId
     */

    vm.changeFieldParentElement = async function (elementIdToUpdateTo, fieldId) {
        try {
            let gridIdsToUpdateTo = await GridFactory.changeFieldParentElement(elementIdToUpdateTo, fieldId);
            vm.elementIdToUpdateTo = null;
            $rootScope.$broadcast('reloadSpecificGrids', gridIdsToUpdateTo.data.data);
            $scope.$apply();
        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * Method to move a field up and down within an element
     * @param direction
     * @param fieldId
     */

    vm.reOrderFields = async function (direction, fieldId, gridId) {
        try {
            await GridFactory.reOrderFields(direction, fieldId);
            let gridIdsToUpdate = [gridId];
            await vm.reloadSpecificGrids(gridIdsToUpdate);
        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * Method expanding or retracting the grid size
     * @param gridId
     * @param lineParentId
     * @param originalGridSize
     * @param newGridSize
     */

    vm.editGridSize = async function (gridId, lineParentId, originalGridSize, newGridSize) {
        try {

            let lineIndexToUpdate;
            // TODO this needed to be added because we had to reset the array keys for the lines
            angular.forEach(vm.lines, function (line, index) {
                if (line.template.id == lineParentId) {
                    lineIndexToUpdate = index;
                }
            });

            vm.lines[lineIndexToUpdate].template.used_width = vm.lines[lineIndexToUpdate].template.used_width - (originalGridSize - newGridSize);
            await GridFactory.editGridSize(gridId, newGridSize);


        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * Method to duplicate a field inside a grid
     * @param field

     */

    vm.duplicateStationField = async function (field, gridId) {
        try {
            let reload = true;
            await scanStation.duplicateStationField(field);
            await AngularScanStationService.callGetScanStationInformationComponentFunction(vm.scanStation.id, 'add-new-field', reload);

            await vm.scanStation.getScanStationRules(vm.scanStation.scanStationObj.id);
            await vm.reloadSpecificGrids([gridId]);

            // TODO: Rafael Check this later
            // await vm.scanStation.updateScanStation('prevent-field-rebuild');
            // $rootScope.$broadcast('reloadSpecificGrids', [gridId]);
            // TODO: Rafael Check this later

            $scope.$apply();

        } catch (e) {
            console.log(e);
            if (e.data.message) {
                alert(e.data.message);
            }
        }

    }

    /**
     * Method to move a row up and down in the bloc
     * @param direction
     * @param gridId
     */

    vm.reOrderGridRow = async function (direction, gridId) {
        try {
            console.log([direction, gridId, vm.scanStation.id])

            await GridFactory.reOrderGridRow(direction, gridId, vm.scanStation.id);
            await vm.loadData();
            $scope.$apply();
        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * This are the actions to set before add a new field to a grid element.
     * @param key
     */
    vm.addFieldElement = (key) => {
        try {
            vm.scanStation.newFieldGridId = key;
            vm.scanStation.showFieldUpdatePopUp = false;
            vm.scanStation.showAddNewField = true;
            vm.fc.initialFieldParams = {};
            vm.fc.initialFieldParams.upperLowerCase = 'mixed-case';
            vm.scanStation.scrollTo('edit-create-fields', '.data-tracking-fields', true);
            vm.fc.showCaseFieldType = 'text';
            vm.scanStation.showInitialFieldParameters = true;
            vm.scanStation.modalOn();

        } catch (exception) {
            console.log(exception);
        }

    }

    /**
     * This is the edit action of an element.
     * @param key
     */
    vm.editFieldElement = async (key, field) => {
        try {
            vm.scanStation.modifyingGridId = key;
            vm.fc.initialFieldParams = {};
            await vm.fc.setInitialFieldParametersFromEdit(field);
            vm.scanStation.editFieldDetails(field.id, field.field_name, field.field_slug, field.field_type, field.field_data);
            vm.scanStation.scrollTo('edit-create-fields', '.data-tracking-fields', true);
            vm.fc.showCaseFieldType = field.field_type;
            vm.scanStation.showInitialFieldParameters = true;
            vm.scanStation.modalOn();
            vm.scanStation.showFieldUpdatePopUp = true;
            vm.scanStation.activeField = field.field_type;
            $scope.$apply();

        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * This will be responsible to load the field rules.
     * @param key
     * @param field
     */
    vm.loadFieldRules = (key, field) => {
        try {
            vm.scanStation.modifyingGridId = key;
            vm.scanStation.currentSelectedFieldParameters = {};
            vm.scanStation.editFieldRules(field, [field.fieldIndex, field.field_order], 'add-rules');
            // vm.scanStation.resetNewRule();
            vm.scanStation.showParametersInSideBar = false;
            vm.scanStation.showRulesInSideBar = true;

        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * This will be responsible to load the field parameters.
     * @param key
     * @param field
     */
    vm.loadFieldParameters = (key, field) => {
        try {
            vm.scanStation.modifyingGridId = key;
            vm.scanStation.currentSelectedFieldParameters = {};
            vm.scanStation.editFieldRules(field, [field.fieldIndex, field.field_order], null);
            // vm.scanStation.resetNewRule();
            vm.scanStation.showParametersInSideBar = true;
            vm.scanStation.showRulesInSideBar = false;

            // reset the Field Visibility Parameter Dropdown
            vm.scanStation.visibilityParamSelection = '';


        } catch (exception) {
            console.log(exception);
        }
    }


    vm.startScanStationFunctions = () => {

        vm.scanStation = vm.scanStation || {};

        vm.scanStation.openRemoteScanStationPopUp = async (remoteScanStationId, recordId, source = false, singleRecordEntryData = null) => {

            vm.scanStation.blockAfterPopUpSubmitEvent = true;
            vm.originalShowMoreSingleDataBox = structuredClone(vm.scanStation.showMoreSingleDataBox);
            vm.originalResultsBoxData = structuredClone(ResultsBoxService.getAllCurrentResultsBoxData());
            vm.originalId = structuredClone(vm.scanStation.scanStationObj.id);
            vm.originalScanStationFields = structuredClone(vm.scanStation.scanStationObj.scanStationFields);
            vm.originalFieldRules = structuredClone(vm.scanStation.fieldRules);
            vm.originalFieldIdsWithRules = structuredClone(vm.scanStation.fieldIdsWithRules);
            vm.originalFieldWithMustBeEqualToAnotherLocalField = structuredClone(vm.scanStation.fieldWithMustBeEqualToAnotherLocalField);
            vm.originalDataToAdd = structuredClone(vm.scanStation.scanStationObj.dataToAdd);
            vm.originalDataToAddDisplayOnly = structuredClone(vm.scanStation.scanStationObj.dataToAddDisplayOnly)
            vm.originalJobId = structuredClone(vm.scanStation.jobID);
            vm.originalStationDetails = structuredClone(vm.scanStation.scanStationObj.stationDetails);
            vm.originalScanStationObj = vm.scanStation.scanStationObj;
            vm.originalScanStationFieldIdsToField = structuredClone(vm.scanStation.scanStationObj.scanStationFieldIdsToField);
            vm.originalScanStationFieldSlugsToField = structuredClone(vm.scanStation.scanStationObj.scanStationFieldSlugsToField);
            vm.originalLines = structuredClone(vm.lines);
            vm.originalParseResultsBoxFieldsArray = structuredClone(vm.scanStation.scanStationObj.parseResultsBoxFieldsArray);
            vm.originalLiveData = structuredClone(vm.scanStation.scanStationObj.liveData);
            vm.originalIsInEditMode = structuredClone(vm.scanStation.scanStationObj.isInEditMode);
            vm.originalInDataEditRowId = structuredClone(vm.scanStation.scanStationObj.inDataEditRowId);

            if (vm.scanStation.scanStationObj.stationDataID !== null) {
                vm.originalStationDataId = structuredClone(vm.scanStation.scanStationObj.stationDataID)
            }

            let remoteScanStationGridLines = await GridFactory.getGridLines(remoteScanStationId);

            // loop through remoteScanStationGridLines.data.lines and usable = true to each line
            for (let line of remoteScanStationGridLines.data.lines) {
                line.usable = true;
            }

            let remoteStation = await AngularScanStationService.getScanStationInformation(remoteScanStationId, 'rule-query', false);

            vm.scanStation.scanStationObj.id = remoteScanStationId;
            vm.scanStation.id = remoteScanStationId;
            vm.scanStation.scanStationObj.scanStationFields = remoteStation.scanStationFields;
            vm.scanStation.jobID = remoteStation.stationDetails.job_id;
            vm.gridPopUpTitle = remoteStation.stationDetails.scan_station_name;
            vm.jobID = remoteStation.stationDetails.job_id;
            vm.scanStation.scanStationObj.stationDetails = remoteStation.stationDetails;

            //await AngularScanStationService.callInitialiseScanStationObjectPropertiesBeforeProcessedFields();

            if (vm.scanStation.scanStationObj.stationDetails.parameters && vm.scanStation.scanStationObj.stationDetails.parameters.length > 0) {

                for (let i = 0; i < vm.scanStation.scanStationObj.stationDetails.parameters.length; i++) {
                    let stationParam = vm.scanStation.scanStationObj.stationDetails.parameters[i];
                    if (stationParam.scan_station_parameter == 'email_alert_options') {
                        vm.scanStation.scanStationObj.stationDetails[stationParam.scan_station_parameter] = JSON.parse(stationParam.parameter_value);
                        if (vm.scanStation.scanStationObj.stationDetails[stationParam.scan_station_parameter].emailAddressParam != null) {
                            vm.scanStation.emailAddressParam = vm.scanStation.scanStationObj.stationDetails[stationParam.scan_station_parameter].emailAddressParam;
                        }

                        if (vm.scanStation.scanStationObj.stationDetails[stationParam.scan_station_parameter].emailParam != null) {
                            vm.scanStation.emailParamDefaultText = vm.scanStation.scanStationObj.stationDetails[stationParam.scan_station_parameter].emailParam;
                        }
                    } else if (stationParam.scan_station_parameter == 'restrictDataByUsername') {
                        vm.scanStation.scanStationObj.stationDetails[stationParam.scan_station_parameter] = (stationParam.parameter_value).replaceAll('"', '').split(',');
                    } else if (stationParam.scan_station_parameter == 'blocRedirect' && vm.scanStation.stateName == 'bloc') {
                        let parameterValueUrl = stationParam.parameter_value;
                        let url = parameterValueUrl.replace(/\\/g, '');
                        url = url.replaceAll('"', '');
                        window.location = url;
                    } else {
                        vm.scanStation.scanStationObj.stationDetails[stationParam.scan_station_parameter] = vm.scanStation.isJson(stationParam.parameter_value)
                            ? JSON.parse(stationParam.parameter_value)
                            : stationParam.parameter_value;
                    }
                }

            }

            // we keep the original customised layout so it doesnt affect the styling in the background
            vm.scanStation.scanStationObj.stationDetails.use_customised_layout = vm.originalStationDetails.use_customised_layout;

            // we also need to always turn off hiding the form even if the child bloc had the setting
            vm.scanStation.scanStationObj.stationDetails.hide_form = 0;

            if (vm.scanStation.gridPopUpObject.gridPopUpTriggerSource == 'triggering-cross-bloc-action' && vm.scanStation.gridPopUpObject.sourceField.dataManipulationOption != 'add-new-record') {
                vm.scanStation.scanStationObj.isInEditMode = true;
            }

            if (vm.scanStation.gridPopUpObject.gridPopUpTriggerSource == 'editing-results-box-record') {
                vm.scanStation.scanStationObj.isInEditMode = true;
            }

            if(vm.scanStation.gridPopUpObject.gridPopUpTriggerSource == 'editing-record-from-calendar') {
                vm.scanStation.scanStationObj.isInEditMode = true;
            }

            if (vm.scanStation.gridPopUpObject.gridPopUpTriggerSource == 'triggering-cross-bloc-action' || vm.scanStation.gridPopUpObject.gridPopUpTriggerSource == 'editing-results-box-record') {
                vm.canShowSaveButton = true;
            } else {
                vm.canShowSaveButton = false;
            }

            await AngularScanStationService.callInitialiseScanStationObjectPropertiesBeforeProcessedFields()

            await vm.scanStation.getScanStationRules(vm.scanStation.scanStationObj.id);
            await vm.scanStation.processScanStationFields();

            //await AngularScanStationService.callInitialiseScanStationObjectPropertiesBeforeProcessedFields(remoteStation);

            if (vm.scanStation.gridPopUpObject.viewType == 'embed-bloc-within-local-bloc') {
                vm.embedState = true;
                let indexToStartMerge = vm.findIndexByFieldId(vm.lines, vm.scanStation.gridPopUpObject.sourceField.id)

                // loop through remoteScanStationGridLines.data.lines and usable = true to each line
                for (let line of remoteScanStationGridLines.data.lines) {
                    line.embedded = true;
                }

                // prepend a row to the remoteScanStationGridLines.data.lines at the start of the array
                remoteScanStationGridLines.data.lines.unshift({
                    "template": {
                        "id": "embedded-row-grid-template-first-row",
                        "used_width": 12,
                        "width": 12
                    },
                    "children": {
                        "grid-1": {
                            "gridfields": []
                        }
                    },
                    "firstEmbeddedRow": true,
                    "usable": true,
                });

                // append a row to the remoteScanStationGridLines.data.lines at the end of the array
                remoteScanStationGridLines.data.lines.push({
                    "template": {
                        "id": "embedded-row-grid-template-last-row",
                        "used_width": 12,
                        "width": 12
                    },
                    "children": {
                        "grid-1": {
                            "gridfields": []
                        }
                    },
                    "lastEmbeddedRow": true,
                    "usable": true,
                });

                // adding 1 to the index because we want it to always appear below the button or trigger for the embed

                vm.noOfEmbeddedLines = remoteScanStationGridLines.data.lines.length;
                vm.indexWhereEmbeddedLineStarts = indexToStartMerge + 1;
                vm.mergeArraysAt(vm.lines, remoteScanStationGridLines.data.lines, indexToStartMerge + 1);

            } else {
                vm.gridPopUpOpen = true;
                vm.gridPopUpLines = remoteScanStationGridLines.data.lines;
            }


            if (vm.scanStation.gridPopUpObject.gridPopUpTriggerSource == 'showing-single-record') {
                vm.scanStation.showMoreSingleDataBox = true;
            }

            // if we have singleRecordEntryData then we dont need to fetch the data again using the recordId
            if (recordId != null && singleRecordEntryData == null) {
                vm.editingRecordId = recordId;
                vm.scanStation.scanStationObj.inDataEditRowId = recordId;
                vm.scanStation.scanStationObj.stationDataID = recordId;
                let singleRecord = await scanStation.getSingleRecordByRecordId(recordId);

                vm.scanStation.scanStationObj.dataToAdd = structuredClone(singleRecord.data.data);

                if (vm.scanStation.gridPopUpObject.gridPopUpTriggerSource != 'showing-single-record') {
                    //loop through dataToAdd and console log the key and value
                    for (let key in vm.scanStation.scanStationObj.dataToAdd) {
                        if (vm.scanStation.scanStationObj.dataToAdd.hasOwnProperty(key)) {
                            let value = vm.scanStation.scanStationObj.dataToAdd[key];
                            let field = vm.scanStation.scanStationObj.scanStationFieldSlugsToField[key];
                            if (field != null) {
                                // call getRules for the field and value of the dataToAdd key
                                await vm.scanStation.getRules('no-event', field.id, field.special_field_key, value, field.domIndex, field.field_slug);
                            }

                        }
                    }
                }

            }

            if (singleRecordEntryData != null) {
                vm.scanStation.scanStationObj.dataToAdd = structuredClone(singleRecordEntryData);
                if (recordId != null) {
                    vm.scanStation.scanStationObj.inDataEditRowId = recordId;
                    vm.scanStation.scanStationObj.stationDataID = recordId;

                }
                //loop through dataToAdd and console log the key and value
                for (let key in vm.scanStation.scanStationObj.dataToAdd) {
                    if (vm.scanStation.scanStationObj.dataToAdd.hasOwnProperty(key)) {
                        let value = vm.scanStation.scanStationObj.dataToAdd[key];
                        let field = vm.scanStation.scanStationObj.scanStationFieldSlugsToField[key];

                        if (field != null) {
                            // call getRules for the field and value of the dataToAdd key
                            await vm.scanStation.getRules('no-event', field.id, field.special_field_key, value, field.domIndex, field.field_slug);
                        }

                    }
                }
            }

            if (singleRecordEntryData == null && recordId == null) {
                vm.scanStation.scanStationObj.stationDataID = null;
                // we are never in edit mode without at least one singleRecordEntryData or recordId
                // this is to handle situations like a cross bloc adding a new record if there's no match during an update
                vm.scanStation.scanStationObj.isInEditMode = false;
                vm.scanStation.scanStationObj.inDataEditRowId = null;
            }

            // pass values from the parent/local bloc to the remote one

            for (let localFieldIdString in vm.scanStation.gridPopUpObject.passThroughDataFieldSlugPairs) {

                let localFieldId = localFieldIdString.replace('_', '');
                let remoteFieldId = vm.scanStation.gridPopUpObject.passThroughDataFieldSlugPairs[localFieldIdString].replace('_', '');


                let localFieldSlug = vm.originalScanStationFieldIdsToField[localFieldId].field_slug;
                let remoteFieldSlug = vm.scanStation.scanStationObj.scanStationFieldIdsToField[remoteFieldId].field_slug;
                if (vm.originalDataToAdd[localFieldSlug] != null) {
                    vm.scanStation.scanStationObj.dataToAdd[remoteFieldSlug] = vm.originalDataToAdd[localFieldSlug];
                    let value = vm.originalDataToAdd[localFieldSlug];
                    let field = vm.scanStation.scanStationObj.scanStationFieldSlugsToField[remoteFieldSlug];
                    if (field != null) {
                        await vm.scanStation.getRules('no-event', field.id, field.special_field_key, value, field.domIndex, field.field_slug);
                    }
                }
            }


            $scope.$apply();
        }

    }

    vm.setUsableOrNot = (line) => {
        if (vm.embedState == true && line.usable == true) {
            return 'usable'
        } else if (vm.embedState == true && line.usable != true) {
            return 'not-usable'
        } else if (vm.embedState != true) {
            return 'usable'
        }

        return 'usable';
    }

    // this closes the pop up but is triggered by a manual close, not a submit
    vm.closeRemoteScanStationPopUpFromXButton = async () => {

        await vm.closeRemoteScanStationPopUp();
        vm.scanStation.gridPopUpObject = {};
    }


    vm.closeRemoteScanStationPopUp = async () => {

        vm.scanStation.blockAfterPopUpSubmitEvent = false;
        if (vm.scanStation.gridPopUpObject.viewType == 'embed-bloc-within-local-bloc') {
            let startIndex = vm.indexWhereEmbeddedLineStarts
            let numberOfElementsToRemove = vm.noOfEmbeddedLines
            vm.removeElementsFromLines(startIndex, numberOfElementsToRemove);
        }

        vm.scanStation.scanStationObj = vm.originalScanStationObj;

        vm.scanStation.id = vm.originalId;
        vm.scanStation.scanStationObj.id = vm.originalId
        vm.scanStation.scanStationObj.scanStationFields = vm.originalScanStationFields
        vm.scanStation.fieldRules = vm.originalFieldRules;
        vm.scanStation.fieldIdsWithRules = vm.originalFieldIdsWithRules
        vm.scanStation.fieldWithMustBeEqualToAnotherLocalField = vm.originalFieldWithMustBeEqualToAnotherLocalField;
        vm.scanStation.jobID = vm.originalJobId;
        vm.scanStation.scanStationObj.jobID = vm.originalJobId;
        vm.scanStation.scanStationObj.stationDetails = vm.originalStationDetails;
        vm.scanStation.scanStationObj.scanStationFieldIdsToField = vm.originalScanStationFieldIdsToField;
        vm.scanStation.scanStationObj.scanStationFieldSlugsToField = vm.originalScanStationFieldSlugsToField;
        // TODO figure out this , and why is it hidden, for some reason this is breaking the parseResultsBoxFieldsArray when you open the popup for a second time, it seems to overwrite the pop up results boxes
        //vm.scanStation.scanStationObj.parseResultsBoxFieldsArray = vm.originalParseResultsBoxFieldsArray;
        vm.scanStation.showMoreSingleDataBox = vm.originalShowMoreSingleDataBox;
        vm.scanStation.scanStationObj.isInEditMode = vm.originalIsInEditMode;
        vm.scanStation.scanStationObj.inDataEditRowId = vm.originalInDataEditRowId;
        vm.scanStation.scanStationObj.liveData = structuredClone(vm.originalLiveData);

        //await AngularScanStationService.callInitialiseScanStationObjectPropertiesBeforeProcessedFields();


        await vm.scanStation.processScanStationFields();

        //let localStation = await AngularScanStationService.getScanStationInformation(vm.scanStation.id, 'rule-query', false);

        //await AngularScanStationService.callGetScanStationInformationComponentFunction(localStation);

        vm.scanStation.scanStationObj.dataToAdd = structuredClone(vm.originalDataToAdd);


        if (vm.originalStationDataId != null) {
            vm.scanStation.scanStationObj.stationDataID = vm.originalStationDataId;
        } else {
            vm.scanStation.scanStationObj.stationDataID = null;
            vm.scanStation.scanStationObj.inDataEditRowId = null;
        }

        await ResultsBoxService.restoreAllCurrentResultsBoxData(vm.originalResultsBoxData);

        vm.gridPopUpTitle = null;
        vm.gridPopUpLines = [];
        vm.gridPopUpOpen = false;
        vm.embedState = false;
        if (vm.scanStation.gridPopUpObject.sourceField != null) {
            let elementId = `scanStationForm-${vm.scanStation.gridPopUpObject.sourceField.id}`;
            vm.scanStation.scrollTo(null, "html, body", false, elementId)
        }

        // TODO fix the issue with radio button watcher, and try remove it if possible

        // this is workaround code to fix the issue with radio buttons not updating when the pop up is closed

        $scope.$applyAsync(async function () {
            // loop through vm.scanStation.scanStationObj.scanStationFields and check if the field type of the object is called "radio-buttons-from-station" and if so console log the field
            for (let field of vm.scanStation.scanStationObj.scanStationFields) {
                if (field.field_type == 'radio-buttons-from-station') {
                    if (!vm.originalDataToAdd[field.field_slug] != null) {
                        vm.scanStation.scanStationObj.dataToAdd[field.field_slug] = vm.originalDataToAdd[field.field_slug];
                        vm.scanStation.getRules('no-event', field.id, field.special_field_key, vm.originalDataToAdd[field.field_slug], field.domIndex, field.field_slug);
                    }
                }
            }

        }, 1500);

        $scope.$apply();
    }

    vm.showGridPopUpSubmitButton = () => {

        let show = false;
        if (vm.scanStation.gridPopUpObject != null && (vm.scanStation.gridPopUpObject.gridPopUpTriggerSource == 'editing-results-box-record' || vm.scanStation.gridPopUpObject.gridPopUpTriggerSource == 'triggering-cross-bloc-action' || vm.scanStation.gridPopUpObject.gridPopUpTriggerSource == 'editing-record-from-calendar' || vm.scanStation.gridPopUpObject.gridPopUpTriggerSource == 'add-new-record-from-calendar')) {
            show = true;
        }

        return show;
    }

    vm.saveDataFromPopUp = async () => {
        let data = {};

        let popUpOrEmbeddedDataToAdd = angular.copy(vm.scanStation.scanStationObj.dataToAdd);

        await vm.scanStation.saveButtonAction(data);

        vm.scanStation.gridPopUpObject.fieldSlugsUpdatedFromPopUp = [];

        if (vm.scanStation.gridPopUpObject.sourceField.localFieldMatchingFieldPairsToSendBackToParent && typeof vm.scanStation.gridPopUpObject.sourceField.localFieldMatchingFieldPairsToSendBackToParent == 'object' && Object.keys(vm.scanStation.gridPopUpObject.sourceField.localFieldMatchingFieldPairsToSendBackToParent).length > 0) {
            let fieldMatchingPairsLength = Object.keys(vm.scanStation.gridPopUpObject.sourceField.localFieldMatchingFieldPairsToSendBackToParent).length;

            for (let i = 0; i < fieldMatchingPairsLength; i++) {
                let localField = vm.originalScanStationFieldIdsToField[vm.scanStation.gridPopUpObject.sourceField.localFieldMatchingFieldPairsToSendBackToParent[i].localFieldId]
                let remoteField = vm.scanStation.scanStationObj.scanStationFieldIdsToField[vm.scanStation.gridPopUpObject.sourceField.localFieldMatchingFieldPairsToSendBackToParent[i].remoteFieldId]

                if (localField != null && remoteField != null) {
                    vm.originalDataToAdd[localField.field_slug] = popUpOrEmbeddedDataToAdd[remoteField.field_slug];
                    vm.scanStation.gridPopUpObject.fieldSlugsUpdatedFromPopUp.push(localField.field_slug);
                }
            }
        }


        await vm.closeRemoteScanStationPopUp();

        if (vm.scanStation.gridPopUpObject != null && vm.scanStation.gridPopUpObject.gridPopUpTriggerSource == 'editing-results-box-record') {

            vm.scanStation.gridPopUpObject.sourceField = await ResultsBoxService.addCurrentResultsBoxFieldToRefreshList(vm.scanStation.gridPopUpObject.sourceField)

            if (vm.scanStation.gridPopUpObject.sourceField.fieldsToRefreshAfterCompletion != null) {
                await ResultsBoxService.callTriggerResultsBoxViaForcedRefreshList(vm.scanStation.gridPopUpObject.sourceField.fieldsToRefreshAfterCompletion);
            }

            await vm.scanStation.updateDropDownsRadiosAutoCompletesForAllFields(vm.scanStation.scanStationObj.scanStationFieldSlugsToField[vm.scanStation.resultsBoxFieldSlug]);
        }

        if (vm.scanStation.gridPopUpObject != null && (vm.scanStation.gridPopUpObject.gridPopUpTriggerSource == 'editing-record-from-calendar' || vm.scanStation.gridPopUpObject.gridPopUpTriggerSource == 'add-new-record-from-calendar')) {
                await CalendarResourcePlanningService.triggerGetDataForCalendarField(vm.scanStation.gridPopUpObject.sourceField);
        }

        if (vm.scanStation.gridPopUpObject != null && vm.scanStation.gridPopUpObject.gridPopUpTriggerSource == 'triggering-cross-bloc-action' && vm.scanStation.blockAfterPopUpSubmitEvent != true) {
            await vm.applyPostButtonClickOrPopUpCrossBlocActions(vm.scanStation.gridPopUpObject.sourceField, vm.scanStation.gridPopUpObject.sourceField.id)
        }

    }


    // from cross bloc contoller, put here to remove event
    vm.applyPostButtonClickOrPopUpCrossBlocActions = async (field, fieldId) => {

        await vm.scanStation.getRules('no-event', fieldId, field.special_field_key, vm.scanStation.scanStationObj.dataToAdd[field.field_slug], field.domIndex, field.field_slug);
        await vm.scanStation.updateDropDownsRadiosAutoCompletesForAllFields(field);

        if(field.fieldsToRefreshAfterCompletion != null) {
            await ResultsBoxService.callTriggerResultsBoxViaForcedRefreshList(field.fieldsToRefreshAfterCompletion);
        }


        if(field.clearFieldsAfterButtonClick != null) {

            for (let key in field.clearFieldsAfterButtonClick) {
                if (field.clearFieldsAfterButtonClick.hasOwnProperty(key)) {

                    let fieldIdToClear = field.clearFieldsAfterButtonClick[key];

                    let fieldToClear = vm.scanStation.scanStationObj.scanStationFieldIdsToField[fieldIdToClear];

                    vm.scanStation.scanStationObj.dataToAdd[fieldToClear.field_slug] = '';

                    // in most cases this should do nothing due it being a blank value, however certain parameters may allow the function to complete
                    await vm.scanStation.getRules('no-event', fieldToClear.id, fieldToClear.special_field_key, vm.scanStation.scanStationObj.dataToAdd[fieldToClear.field_slug], fieldToClear.domIndex, fieldToClear.field_slug);
                }
            }
        }


        if(field.clickOnFieldAfterButtonClick != null && field.clickOnFieldAfterButtonClick != '') {

            let inputField = document.querySelector(`input[data-field-id="${field.clickOnFieldAfterButtonClick}"]`);
            // Focus on the element if it exists
            if (inputField) {
                (inputField as HTMLElement).focus();
                (inputField as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            }
        }

        if(vm.scanStation.gridPopUpObject != null && vm.scanStation.gridPopUpObject.fieldSlugsUpdatedFromPopUp != null) {
            for (var key in vm.scanStation.gridPopUpObject.fieldSlugsUpdatedFromPopUp) {
                let fieldSlug = vm.scanStation.gridPopUpObject.fieldSlugsUpdatedFromPopUp[key];
                let field = vm.scanStation.scanStationObj.scanStationFieldSlugsToField[fieldSlug];
                await vm.scanStation.getRules('no-event', field.id, field.special_field_key, vm.scanStation.scanStationObj.dataToAdd[fieldSlug], field.domIndex, fieldSlug);
            }
        }

        vm.scanStation.gridPopUpObject = {}

        $scope.$applyAsync();
    }


    /**
     * This function merges the two arrays of grid lines, one from the remote bloc
     * and one from the local bloc, it inserts the remote blocs inside a desired location within
     * the first array, the location is determined by startIndex
     * TODO to be used when we make the cross bloc embed within form option live
     * @param array1
     * @param array2
     * @param startIndex
     */
    vm.mergeArraysAt = (array1, array2, startIndex) => {
        array1.splice(startIndex, 0, ...array2);
    }

    vm.removeElementsFromLines = (startIndex, numberOfElementsToRemove) => {
        vm.lines.splice(startIndex, numberOfElementsToRemove);
    }

    /**
     * This function finds the index of a field_id within the grid lines array
     * @param lines
     * @param fieldId
     */
    vm.findIndexByFieldId = (lines, fieldId) => {
        for (let i = 0; i < lines.length; i++) {
            const children = lines[i].children;
            for (const key in children) {
                if (children.hasOwnProperty(key)) {
                    const gridFields = children[key].gridfields;
                    for (const field of gridFields) {
                        if (field.field_id === fieldId) {
                            return i; // Return index where the field_id is found
                        }
                    }
                }
            }
        }
        return -1; // Return -1 if not found
    }

    vm.isNotEmbeddedStartOrFinishRow = (line) => {
        if (line.firstEmbeddedRow == true || line.lastEmbeddedRow == true) {
            return false;
        }
        return true;
    }

    vm.allowActionsToBeTriggered = () => {
        vm.scanStation.allowActionsAfterShowMore = true;
        vm.scanStation.allowActionsAfterEditClick = true;
    }

}

export default GridScanStationController;
