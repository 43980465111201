import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .directive('buttonField', buttonFieldDirective)
       .directive('buttonFieldPrint', buttonFieldPrintDirective)
    .directive('buttonFieldBuild', buttonFieldBuild)
    .service('buttonField', buttonField);

buttonFieldDirective.$inject = ['Configuration'];
/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function buttonFieldDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/button/field.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

buttonFieldPrintDirective.$inject = ['Configuration'];
/**
 * Directive that will show the field result in the table.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function buttonFieldPrintDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/button/print.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl,
        controllerAs: 'fc'
    });
}

/**
 * Service constructor.
 * @constructor
 */
function buttonField() {
    let service = this;

    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try
        {
            let initialParams = {
                'showTabStyleToggleView': {
                    'validation': {
                        'required' : false
                    }
                },
                'pressedUnpressedValues': {
                    'subParameters': {
                        'pressed': {
                            'validation' : {
                                'required': true,
                                'validationResponse': 'Please provide a value for when the button is pressed',
                                'requiredType': 'stringMustNotBeEmpty'
                            }
                        },
                        'unPressed': {
                            'validation' : {
                                'required': true,
                                'validationResponse': 'Please provide a value for when the button is unpressed',
                                'requiredType': 'stringMustNotBeEmpty'
                            }
                        }

                    },
                    'validation' : {
                        'required': true,
                        'validationResponse': 'Please provide values for the pressed and unpressed options',
                        'requiredType': 'listMustHaveOne'
                    }
                },
                "fontAwesomeIcon": {
                    'validation': {
                        'required' : false
                    }
                },
                "buttonColour": {
                    'validation': {
                        'required' : false
                    }
                },
                "enableButtonShowMore": {
                    'validation': {
                        'required' : false
                    }
                }
            }


            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }
}

/**
 * Directive object that will build or edit the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function buttonFieldBuild(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/button/build-edit.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}
